import {memo, useCallback, useMemo, useState} from 'react';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useExtractCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useTranslation} from 'react-i18next';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import {MobileTreeFilter} from '@tehzor/ui-components';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';

interface ICategoriesSetFilterMobileProps {
	objectId?: string;
	value?: string[];
	entity: string;
	label?: string;
	onChange: (changes: IChanges) => void;
}

export const CategoriesSetFilterMobile = memo(
	({objectId, value, entity, onChange, label}: ICategoriesSetFilterMobileProps) => {
		const {t} = useTranslation();
		const translatedLabel = label ?? t('entitiesFilters.CategoriesSetFilter.label');

		const [search, setSearch] = useState('');

		const {data: allCategories} = useExtractAllCategoriesAsArray();
		const {data: categoriesSets} = useExtractCategoriesSetsAsArray(objectId || 'all');

		const treeData = useMemo(
			() => makeTreeData(categoriesSets, allCategories),
			[categoriesSets, allCategories]
		);

		const {filteredData} = useMemo(
			() => treeFilter(treeData, 'content', search),
			[search, treeData]
		);

		const handleChange = useCallback(
			(v: string[] | undefined) => {
				onChange({categories: v});
			},
			[onChange]
		);

		const handleExpand = useFilterPageTransition(objectId, entity, 'categories');

		return (
			<MobileTreeFilter
				label={translatedLabel}
				data={filteredData}
				selected={value}
				linkBtnLabel={t('problemsFiltersPage.allBtn.label')}
				onChange={handleChange}
				onExpand={handleExpand}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={setSearch}
					/>
				}
			/>
		);
	}
);
