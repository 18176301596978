import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

const processClassMap: Record<ProcessIds, string> = {
	[ProcessIds.OPERATIONAL_CONTROL]: 'tz-check-28',
	[ProcessIds.ACCEPTANCE_CONTROL]: 'tz-work-acceptance-28',
	[ProcessIds.INCOMING_MATERIALS_CONTROL]: '',
	[ProcessIds.INCOMING_DOCUMENTATION_CONTROL]: '',
	[ProcessIds.GEODETIC_CONTROL]: '',
	[ProcessIds.LABORATORY_CONTROL]: '',
	[ProcessIds.LABOR_PROTECTION_CONTROL]: '',
	[ProcessIds.INTERNAL_ACCEPTANCE]: 'tz-internal-acceptance-28',
	[ProcessIds.PRESALE_CONTROL]: '',
	[ProcessIds.UNITS_HANDOVER]: 'tz-owner-acceptance-28',
	[ProcessIds.OBJECT_HANDOVER]: '',
	[ProcessIds.WARRANTY_SERVICE]: 'tz-warranty-claim-28',
	[ProcessIds.TECHNICAL_SERVICE]: '',
	[ProcessIds.AUDIT]: ''
};

export const getProcessIcon = (processId: ProcessIds) => processClassMap[processId] || '';
