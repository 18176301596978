import {useMemo, useRef} from 'react';
import './WorkAcceptancesPage.less';
import classNames from 'classnames';
import useAsync from 'react-use/lib/useAsync';
import {LoadingPanel} from '@tehzor/ui-components';
import {WorkAcceptancesPageCounter} from './components/WorkAcceptancesPageHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {WorkAcceptancesFilters} from './components/filters/WorkAcceptancesFilters';
import {WorkAcceptancesTable} from './components/table/WorkAcceptancesTable';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {ObjectPageBreadcrumbs} from './components/ObjectPageBreadcrumbs';
import {useParams} from 'react-router-dom';
import {getWorkAcceptancesProblemsData} from '@src/store/modules/pages/workAcceptances/actions/getWorkAcceptancesProblemsData';
import {useWorkAcceptances} from '@src/core/hooks/queries/workAcceptances';
import {getSpacesList} from '@src/store/modules/entities/spaces/actions';
import {getCheckRecords} from '@src/store/modules/pages/checkLists/actions';
import {WorkAcceptancesSearch} from '@src/pages/WorkAcceptancesPage/components/WorkAcceptancesSearch';

export const WorkAcceptancesPage = ({className}: {className?: string}) => {
	useScrollRestoration();

	const loadingStatusRef = useRef<boolean>(true);

	const {objectId} = useParams<{objectId?: string}>();
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {state} = useEntitiesFiltersCtx();

	const {data: workAcceptances, isSuccess} = useWorkAcceptances({objectId, argsFilters: state});

	const spaceIds = useMemo(
		() =>
			Array.from(
				new Set(
					workAcceptances?.allIds.reduce((ids: string[], waId: string) => {
						const waSpaceIds = workAcceptances.byId[waId].spaceIds;
						return waSpaceIds ? [...ids, ...waSpaceIds] : ids;
					}, [])
				)
			),
		[workAcceptances]
	);

	useAsync(async () => {
		if (isSuccess) {
			const workAcp = workAcceptances.allIds.map(id => ({
				objectId: workAcceptances.byId[id].objectId,
				workAcceptanceId: workAcceptances.byId[id].id
			}));
			await dispatch(getWorkAcceptancesProblemsData({workAcceptances: workAcp}));
			await dispatch(
				getCheckRecords(
					undefined,
					objectId ? [objectId] : undefined,
					undefined,
					workAcceptances.allIds
				)
			);
			if (spaceIds) {
				await dispatch(getSpacesList(objectId || 'all', {ids: spaceIds}));
			}
			loadingStatusRef.current = false;
		}
	}, [objectId, workAcceptances, isSuccess, spaceIds]);

	return (
		<LoadingPanel
			className="work-acceptances-page__loading-panel"
			active={loadingStatusRef.current}
		>
			<div
				className={classNames(
					'page-cont',
					'work-acceptances-page',
					{'work-acceptances-page_small-margin': !objectId},
					className
				)}
			>
				{objectId !== undefined && (
					<ObjectPageBreadcrumbs
						isDesktop={isDesktop}
						objectId={objectId}
					/>
				)}
				<div className="work-acceptances-page__filters-bar">
					{!isDesktop && <WorkAcceptancesSearch />}
					<WorkAcceptancesFilters objectId={objectId} />
				</div>
				<WorkAcceptancesPageCounter objectId={objectId} />
				<WorkAcceptancesTable objectId={objectId} />
			</div>
		</LoadingPanel>
	);
};
