import {useMutation} from '@tanstack/react-query';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {
	IMakeTaskResponsibleRequestData,
	makeTaskResponsible
} from '@src/api/backend/task/editResponsible';
import {addErrorToast} from '@src/utils/toasts';
import {queryClient} from '@src/api/QueryClient';
import {delegationHistoryQueryKeys} from '@src/api/cache/delegationHistory/keys';

export const useEditTaskResponsible = () =>
	useMutation({
		mutationKey: tasksQueryKeys.edit(),
		mutationFn: (data: IMakeTaskResponsibleRequestData) => makeTaskResponsible(data),
		onError: () => addErrorToast('Ошибка', 'при изменении ответственных'),
		onSuccess: data => {
			queryClient.setQueryData(
				tasksQueryKeys.detail(data.id),
				(task: IMakeTaskResponsibleRequestData | undefined) =>
					task ? {...task, ...data} : data
			);
			void queryClient.invalidateQueries({queryKey: tasksQueryKeys.all()});
			void queryClient.invalidateQueries({
				queryKey: delegationHistoryQueryKeys.histories({
					objectId: data.objectId,
					taskId: data.id
				})
			});
		}
	});
